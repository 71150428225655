
.gallery{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%; 
column-width: 33%; 
padding: 0 12px;

}
.header-gallery{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 4rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.gallery .pics{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

@media (max-width: 991px){
    .gallery{
        -webkit-column-count: 2;
        -moz-column-count: 2; 
        column-count: 2;
 
        }
}

@media (max-width: 480px){
    .gallery{
        -webkit-column-count: 1;
        -moz-column-count: 1; 
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%; 
        column-width: 100%; 
       
        }
} 
@media screen and (max-width: 768px){
    .header-gallery{
        flex-direction: column;
        gap: 1rem;
        line-height: 2rem;
        font-size: xxx-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
}
@media screen and (max-width: 1288px){
    .header-gallery{
        flex-direction: column;
        gap: 1rem;
        line-height: 3rem;
        font-size: xxxx-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
}