/* .Footer-container{
    position: relative;
}

.Footer>hr{
    border: 1px solid var(--lightgray);
}

.Footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
}


.btn:hover{
    cursor: pointer;
}

.blur{
    background: rgba(253, 120, 43, 0.69);
    position: absolute;
    border-radius: 50%;
    filter: blur(212px);
    z-index: -9;
}

.Footer-container .copyright{
    text-align: center;
    font-size: 1rem;
    color: white;
}

logo-f{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
} */
.footer {
    background-color: #111;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }

  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
  }

  .footer-about>img{
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: auto;
    margin-top: 1px;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  .footer-about>p{
    font-size: 1.2rem;
    /* line-height: 1.6; */
    /* margin-top: -160px; */
  }
  
  .footer-about, .footer-links, .footer-contact {
    flex: 1;
    min-width: 200px;
  }
  
  .footer h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .footer p {
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links ul li {
    margin: 5px 0;
  }
  
  .footer-links ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-links ul li a:hover {
    color: #f39c12;
  }
  
  .footer-socials a {
    margin-right: 10px;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
 
  }
  
  .footer-socials a:hover {
    color: #f39c12;

  }
  
  .footer-bottom {
    margin-top: 20px;
    font-size: 0.8rem;
    color: #ccc;
  }
@media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: left;
    }
    .footer-links ul li a:hover {
      color: #f39c12;
    }
  }
   