.hero{
    display: flex;
    justify-content: space-between;
}

.left-h{
    padding: 1rem;
    padding-top: 1.5rem;
    flex: 3;
    display:flex;
    gap: 2rem;
    flex-direction: column;
}

.right-h{
    flex:1;
    position: relative;
    background-color: var(--orange);
    /* align-items: center; */


}

.the-best-ad{
    margin-top: 2rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 16px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.hero-text{
    display:flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 60%;
}

.figures{
display: flex;
gap: 2rem;
}

.figures>div{
    display:flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}

.hero-buttons{
    display: flex;
    gap: 2rem;
    font-weight: normal;
}

.hero-buttons>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 8rem;
}

.hero-buttons>:nth-child(2){
    color: white;
    background-color: transparent;
    border: 1px solid var(--orange);
    width: 8rem;
}

.right-h>.btn{
    position: absolute;
    right: 5rem;
    top: 2rem;
    color:black;
}

.total-event{
  display:flex;
  gap:2rem;
  background-color: var(--caloryCard); 
  border-radius: 5px;
  padding: 1rem;
  width:fit-content;
  position:absolute;
  top:40rem;
  right: 50rem;
}

.total-event>div{
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    justify-content: space-between;
}

.total-event>img{
   width: 3rem;
}

.total-event>div{
    display: flex;
    flex-direction: column;
}

.total-event>div>:nth-child(1){
    color:var(--gray);

}
.total-event>div>:nth-child(2){
    color:white;
    font-size: 2rem;
}

.hero-image{
    position: absolute;
    top: 4rem;
    right:2rem;
    width: 45rem;
}

@media screen and (max-width: 1350px){
    .total-event{
        display:none;
       
      }
      
}
@media screen and (max-width: 1350px){
  
      .hero-image{
        filter: brightness(20%);
      }
  
      
}
@media screen and (max-width: 1159px){
    .right-h>button{
        display: none;
    }
    
}

@media screen and (max-width: 900px){
    .hero{
        flex-direction: column;;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .hero-text > div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    /* .hero-text>div:nth-of-type(3){
        font-size: 1rem;
        font-weight: 200;
        text-transform: none;
        letter-spacing: 1px;
        width: 100%;
    }
     */
    .hero-buttons{
        justify-content: center;

    }
    .figures{
        display: flex;
        justify-content: center;
        /* flex-direction: column;
        align-items: center; */
        gap: 1rem;
    }
    .figures >div >span:nth-of-type(1){
        font-size: x-large;
    
    }
    .figures > div>span:nth-of-type(2){
        font-size: large;
     
    }
    .right-h{
        position:relative;
        background: none;
    }
    .right-h, .btn{ 
        margin-top: -10px;
    margin-right: 22px;
    }
    .hero-image{
        position: relative;
        width: 20rem;
        left: 0.6rem;
        top: 5rem;
        align-self: center;
    }
    .hero-image{
        /* filter: brightness(80%); */
        display: none;
      }
}


@media screen and (max-width: 748px){
    .hero{
        flex-direction: column;;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: x-large;
        align-items: center;
        justify-content: center;
    }
    .hero-text > div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .hero-image{
        /* filter: brightness(80%); */
        display: none;
      }
}





