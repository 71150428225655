.programs{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 0 2rem;
}

.programs-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3.5rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.programs-categories{
    /* display:flex; */
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 3 columns */
    grid-template-rows: repeat(2, auto); /* 2 rows */
}

.category{
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    /* border-radius: 10px; */
    color: white;
    /* align-items: center; */
    justify-content: space-between;
}

.category>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
}
.category>:nth-child(2){
  font-size: 1rem;
  font-weight:bold;
}

.category>:nth-child(3){
  font-size: 0.9rem;
  line-height: 25px;
}
.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.join-now> img{
    width: 1rem;
}

.category:hover{
    background-color: var(--orange);
}
.join-now a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .join-now>btn{
    padding: 1rem 2rem;
    background-color: var(--orange);
  }

  @media screen and (max-width: 1408px){
    .programs-header{
        /* flex-direction: column; */
        gap: 1rem;
        line-height: 1.5rem;
        font-size: xxx-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .programs-categories{
        /* flex-direction: column; */
        display: grid;
    grid-template-columns: repeat(4, 1fr); /* 3 columns */
    grid-template-rows: repeat(8, auto); 
    }
}

@media screen and (max-width: 1036px){
    .programs-header{
        /* flex-direction: column; */
        gap: 1rem;
        line-height: 1.5rem;
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .programs-categories{
        /* flex-direction: column; */
        display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-template-rows: repeat(8, auto); 
    }
}
@media screen and (max-width: 700px){
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        line-height: 1.5rem;
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    .programs-categories{
        /* flex-direction: column; */
        display: grid;
    grid-template-columns: repeat(2, 1fr); /* 3 columns */
    grid-template-rows: repeat(8, auto); 
    }
   
}
@media screen and (max-width: 450px){
    .programs-header{
        /* flex-direction: column; */
        gap: 1rem;
        line-height: 1.5rem;
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .programs-categories{
        /* flex-direction: column; */
        display: grid;
    grid-template-columns: repeat(1, 1fr); /* 3 columns */
    grid-template-rows: repeat(8, auto); 
    }
}
