.Reason{
    padding: 0 2rem;
    display: flex;
    grid-template: 2rem;
}

.Left-r{
    display:flex;
    
    flex: 1.6 1;
    /* display:grid; */
    /* grid-template-columns: repeat(3, 1fr); */
    gap: 1rem;
    /* grid-auto-rows: 1fr; */
} 
/* VideoComponent.css */
.video-container {
    position: relative;
    width: 100%;
    /* margin-right: 1rem; */
    padding-bottom: 56.25%;
     /* 16:9 Aspect Ratio */
    overflow: hidden;
  }
  
.responsive-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; 
    /* Ensures the video scales properly */
}
  
.right-r{
    flex:1 1;
    text-transform: uppercase;
    margin-left: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-r>span{
    font-weight: bold;
    color: Var(--orange);
}

.right-r>div{
    color: White;
    font-size: 3rem;
    font-weight: 1rem;
}

.detail-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.detail-r>div{
    display: flex;
    font-size: 1rem;
    gap:1rem;
}

.detail-r>div>img{
    width: 2rem;
    height: 2rem;
}

.partners{
    display: flex;
    gap: 1rem;
}
.partners>img{
    width: 2rem;
}

  @media screen and (max-width: 768px){
    .Reason{
        flex-direction: column;
    }
    .right-r>span{
        margin-top: 1rem;
    }
   
  }